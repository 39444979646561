import MediaCardCaption from "./media-card-caption";
import MediaCardFigure from "./media-card-figure";
import { useMediaCard, type UseMediaCardProps } from "./use-media-card";

export type MediaCardProps = UseMediaCardProps;

function MediaCard(props: MediaCardProps) {
  const {
    className,
    styles,
    theme,
    data,
    isYoutubeVideo,
    isHorizontalLayout,
    showCaption,
    hasContent,
  } = useMediaCard(props);

  return (
    <article
      className={styles.base({ className })}
      data-component-name="media-card"
    >
      <div className={styles.figureWrapper()}>
        <div className={styles.figure()}>
          <MediaCardFigure
            data={{
              title: data.title,
              cover: data.cover,
              url: data.url,
            }}
            isYoutubeVideo={isYoutubeVideo}
            styles={styles}
          />
        </div>
      </div>
      {showCaption ? (
        <MediaCardCaption
          data={data}
          hasContent={hasContent}
          isHorizontalLayout={isHorizontalLayout}
          isYoutubeVideo={isYoutubeVideo}
          styles={styles}
          theme={theme}
        />
      ) : null}
    </article>
  );
}

export default MediaCard;
